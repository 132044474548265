import { Fragment, lazy } from "react";
import useAnalytics from "./useAnalytics";
// import BrowserRouter from "react-router-dom/BrowserRouter.js";
const BrowserRouter = require("react-router-dom").BrowserRouter;
// import Switch from "react-router-dom/Switch.js";
const Switch = require("react-router-dom").Switch;
// import Route from "react-router-dom/Route.js";
const Route = require("react-router-dom").Route;


const Blog = lazy(() => import("./components/blog/blog"));
const AboutUsMain = lazy(() => import("./components/about-new/about-us"));
const ContactUsMain = lazy(() => import("./components/contact-new/contact-us"));
const PrivacyPolicyMain = lazy(() => import("./components/privacy-new/privacy-policy"));
const HomeMain = lazy(() => import("./components/home-new/home-new.js"));
const LatestCategory = lazy(() => import("./components/latest-category/latest-category"));
const CategoryAll = lazy(() => import("./components/category/category"));
const Error404 = lazy(() => import("./components/error-pages/error404"));


function AppRoutes() {
    useAnalytics();
    return (
        <Fragment>
            <BrowserRouter>
            {/* {location.pathname!=='/:categorie1/:articleId/:id?utm_source=:utm_source&utm_medium=:utm_medium&utm_campaign=:utm_campaign&article_type=:article_type' ? <HeaderMenu/>:null} */}
                <Switch>
                    <Route path="/" exact component={HomeMain} />
                    <Route exact path="/:categorie1/:articleId/:id?utm_source=:utm_source&utm_medium=:utm_medium&utm_campaign=:utm_campaign&article_type=:article_type" component={Blog} />
                    <Route exact path="/:categorie1/:articleId/:id?utm_source=:utm_source&utm_medium=:utm_medium&utm_campaign=:utm_campaign" component={Blog} />
                    <Route exact path="/:categorie1/:articleId/:id" component={Blog} /> {/*For query string url*/}
                    <Route exact path="/aboutus" component={AboutUsMain} />
                    <Route exact path="/contactus" component={ContactUsMain} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicyMain} />
                    <Route exact path="/latest/category/:utm_source/:ad_id" component={LatestCategory} />
                    <Route exact path="/category/:catId/:utm_source/:ad_id" component={CategoryAll} />
                    <Route  path="*" component={Error404} />
                </Switch>
            </BrowserRouter>
        </Fragment>
    );
}

export default AppRoutes;
